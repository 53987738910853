.waitlist-error{
  height: 0;
  width: 100%;
}

.waitlist-error-colored{
  height: 4vh;
  border: 1px solid var(--yellow);
  opacity: 0.7;
  border-radius: 5px;
  background: var(--yellow);

  margin-top: 2vh;
}

.waitlist-wrapper {
  border: 2px solid var(--yellow);
  border-radius: 5px;
  min-width: 25vw;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.waitlist-input {
  height: 40px;
  padding-left: 10px;
}

.waitlist-button {
  height: 40px;
  border: 1px solid var(--yellow);
  background: var(--yellow);
  margin: 0;
}

.waitlist-background {
  background-color: var(--yellow);
}

@media only screen and (max-width: 1024px) {
  .waitlist-wrapper {
    flex-direction: column;
    border: 0;
    width: 80vw;
  }

  .waitlist-input {
    width: 60vw;
    margin-bottom: 20px;
    border: 2px solid var(--yellow);
    border-radius: 5px;
  }

  .waitlist-button {
    border: 2px solid var(--yellow);
    border-radius: 5px;
  }
}

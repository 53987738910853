.burger-button {
  display: none;
}

.navbar {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.steady-logo{
  max-width: 10%;
  height: auto;
}

.navbar-links{
  max-width: 60%;
  height: 100%;
}

.navbar > .navbar-links > a {
  margin-left: 30px;
}

.get-access-button{
  background-color: var(--aquamarine);
  color: var(--black);
}

@media only screen and (max-width: 1024px) {

  .steady-logo{
    display: none;
  }

  .navbar {
    position: relative;
    z-index: 2;
  }

  .burger-button {
    display: block;
    font-size: 17px;
    background: var(--yellow);
    border-color: var(--yellow);
  }

  .navbar {
    flex-direction: column;
    position: absolute;
    top: 0;
    background-color: var(--yellow);
    width: 100%;
    transform: translateY(-110%);
  }

  .navbar-links{
    flex-direction: column;
    width: 100%;
    margin-top:20px;
  }

  .navbar > .navbar-links > a {
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .navbar-mobile-enter {
    transform: translateY(0%);
    transition: all 0.75s ease-in-out;
  }

  .navbar-mobile-exit {
    transform: translateY(-110%);
    transition: all 0.75s ease-in-out;
  }

  .navbar > a {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .get-access-button{
    background-color: transparent;
    color: var(--black);
    border-color: transparent;
    text-shadow: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.015);
  }
}

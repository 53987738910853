.logo {
  display: None;
}
.overlay{
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.intro-title {
  position: relative;
  min-height: 650px;
  width: 100%;
  text-align: center;
  padding: 20px;
}

.intro-title-subheader {
  width: 65%;
  margin-top: 20px;
  text-align: center;
}

.intro-header {
  width: 80%;
  color: white;
  z-index: 2;
}

.counter-bottom{
  width: 100%;
  padding: 20px;
  background-color: var(--light-yellow);
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .logo {
    display: block;
    max-width: 20vw;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .intro-title {
    margin-top: 20px;
    min-height: 350px;
  }

  .intro-header {
    width: 90%
  }

  .intro-title-subheader {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  .intro-header {
    width: 90%
  }
}

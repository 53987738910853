.benefits-wrapper {
  min-height: 50vh;
  padding: 20px;
  width: 100%;
}
.benefits-image {
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 40%;
  height: 100%;
}
.benefits-text {
  min-width: 30%;
  max-width: 60%;
  padding-left: 50px;
}
.benefits-text > div {
  margin-bottom: 20px;
}
.benefit-store-buttons{
  justify-content: start;
}
.benefits-header {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .benefits-wrapper {
    flex-direction: column;
    min-height: 30vh;
    padding: 10px;
  }
  .benefits-image {
    max-width: 80%;
    height: auto;
    object-fit: cover;
  }
  .benefits-text {
    min-width: 30%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
  }
  .benefits-header {
    justify-content: center;
  }
  .store-image {
    height: 50px;
    width: auto;
}
.benefit-store-buttons{
  justify-content: center;
}

}

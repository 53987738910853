@import "~antd/dist/antd.css";
body {
  margin: 0;
  padding: 0;
  font-family: Avenir;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

p {
  margin: 0;
}

@font-face {
  font-family: "Avenir";
  src: url(./fonts/AvenirNext-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir-heavy";
  src: url(./fonts/AvenirNext-Heavy.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir-black";
  src: url(./fonts/Avenir-Black.otf) format("opentype");
}

a {
  color: inherit;
}

.recommended-readings-wrapper {
  min-height: 50vh;
  width: 100%;
  padding-left: 2vw;
  margin-top: 20px;
  margin-bottom: 20px;
}
.recommended-readings-header {
  width: 100%;
  margin-bottom: 20px;
}
.blog-wrapper {
  width: 100%;
  overflow: auto;
  padding-bottom: 30px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.blog-tile-wrapper {
  flex: 0 0 30vw;
  height: 420px;
  background-color: white;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 21px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 21px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 21px -3px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-right: 30px;
}
.blog-tile-image {
  height: 50%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.blog-tile-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  height: 25%;
  width: 100%;
}
.blog-tile-desc {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  height: 40%;
  width: 100%;
  text-align: justify;
  overflow: hidden;
  line-height: 1.6;
}

@media only screen and (max-width: 801px) {
  .blog-tile-wrapper {
    flex: 0 0 70vw;
  }

  .recommended-readings-wrapper {
    min-height: 50vh;
    width: 100%;
    padding-left: 8vw;
    margin-bottom: 5vh;
  }
}

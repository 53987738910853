.journey-wrapper {
  padding: 5px;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
  max-width: 1300px;
}
.journey-header{
  margin-bottom:20px;
}
.arrow-wrapper{
  height:100%;
}
.arrow{
  width: 30px;
}
.section-row-wrapper{
  min-height: 270px;
}
.section-wrapper{
  width: 30%;
  padding: 20px;
  min-height: 250px;
}
.section-icon{
  height: 170px;
  width: auto;
}
.section-header{
  margin-top:10px;
  text-align: center;
}
.section-subheader{
  margin-top:10px;
  text-align:center;
}


.journey-text {
  height: 50%;
  width: 60%;
  margin-left: 20px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .journey-wrapper {
    padding: 0px;
    margin-bottom: 0px;
    width: 100%;
    max-width: 1300px;
  }
  .section-row-wrapper{
    flex-direction: column;
    height:auto;
    min-height: 50vh;
  }
  .section-header{
    min-height: auto;
  }
  .section-wrapper{
    width: 100%;
    padding-top: 20px;
    min-height: auto;
  }
  .arrow-wrapper{
    width: 100%;
  }
  .arrow{
    transform: rotate(90deg);
  }
  .section-subheader{
    min-width: 300px;
    max-width: 450px;
  }

  
}

.footer-wrapper{
    min-height: 300px;
    width: 100%;
    background-color: var(--light-green);
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
}

.footer-content-wrapper{
    min-height: 150px;
}

.footer-logo{
    max-height: 100%;
    max-width:20%;
}

.footer-links{
    min-width: 30%;
    max-width: 50%;
    height: 100%;
}

.footer-links > a {
    margin-top:10px;
}

.footer-social{
    min-width: 20%;
    max-width:30%;
}
.footer-social-text{
    width: 100%;
    height:100%;
}
.footer-social-buttons{
    width: 100%;
    height:100%;
}
.footer-social-icon{
    font-size:30px;
    margin-right: 10px;
}
.footer-social-buttons > a{
    max-width: 50%;
}

@media only screen and (max-width: 600px) {
    .footer-content-wrapper{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .footer-content-wrapper {
        min-height: 250px;
    }
    .footer-logo {
        max-height: 30%;
        max-width: 50%;
    }
    .footer-links {
        min-width: 30%;
        max-width: 50%;
        height: 50%;
        margin: 10px;
    }
    .footer-social {
        min-width: 30%;
        max-width: 50%;
        margin: 10px;
    }
}
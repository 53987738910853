:root {
  --meet-counsellor-wrapper-paddng: 40px;
  --counsellor-image-desc-wrapper-margin-right: 40px;
  --image-desc-width: 570px;
  --image-size: 249px;
  --shadow-gap: 5px;
  --qualifications-image: 60px;
}

.meet-counsellor-wrapper {
  min-height: 40vh;
  width: 100%;
  padding-top: var(--meet-counsellor-wrapper-paddng);
  margin-bottom: 40px;
}

.meet-counsellor-header {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--black);
  padding-right: var(--meet-counsellor-wrapper-paddng);
  padding-left: var(--meet-counsellor-wrapper-paddng);
}
.meet-counsellor-policies-wrapper {
  min-height: 400px;
  width: 100%;
  margin-left: var(--meet-counsellor-wrapper-paddng);
  margin-right: var(--meet-counsellor-wrapper-paddng);
  position: relative;
  /* background-repeat: no-repeat;
  background-size:cover;
  background-position: center; */
}
.background-image{
  width:100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.counsellor-image-desc-wrapper {
  margin-right: var(--counsellor-image-desc-wrapper-margin-right);
  width: var(--image-desc-width);
}
.counsellor-image-wrapper {
  height: calc(var(--image-size) + (var(--shadow-gap) * 2));
  width: calc(var(--image-size) + (var(--shadow-gap) * 3));
  position: relative;
}
.counsellor-image-shadow-one {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #EDF8FF;
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 15px;
}
.counsellor-image-shadow-two {
  position: absolute;
  right: var(--shadow-gap);
  bottom: var(--shadow-gap);
  width: var(--image-size);
  height: var(--image-size);
  background-color: #D7EEFE;
  border-radius: 15px;
}
.counsellor-image-top {
  width: var(--image-size);
  height: var(--image-size);
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: calc(var(--shadow-gap) * 2);
  bottom: calc(var(--shadow-gap) * 2);
  border-radius: 15px;
}
.counsellor-desc-wrapper {
  background-color: white;
  max-height: calc(var(--image-size) - (var(--shadow-gap) * 3));
  max-width: calc(
    var(--image-desc-width) - var(--image-size) - (var(--shadow-gap) * 3)
  );
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
  margin-top: calc(var(--shadow-gap) * 2 + 5px);
  padding: 10px;
  padding-left: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.counsellor-qualifications-image-wrapper > ul > li {
  padding-left: 15px;
}
.counsellor-qualifications-pointer {
  float: left;
  margin-right: 20px;
}

.counsellor-qualifications-image-wrapper {
  flex-wrap: wrap;
}
.counsellor-qualifications-image {
  height: var(--qualifications-image);
  object-fit: contain;
  margin: 5px;
}
.meet-counsellor-policies-pointers {
  margin: 5px;
  max-width: calc(
    100vw - var(--image-desc-width) -
      (var(--meet-counsellor-wrapper-paddng) * 2) -
      var(--counsellor-image-desc-wrapper-margin-right) -
      var(--counsellor-image-desc-wrapper-margin-right)
  );
}

.pointer-wrapper {
  margin-top: 40px;
}
.pointer-tick {
  margin-right: 20px;
  color: var(--aquamarine);
  font-size: 38px;
}
.pointer-desc {
  text-align: justify;
  color: var(--black);
}
.featured-wrapper {
  margin-top: 40px;
  color: var(--black);
  width: 100%;
}

.featured-institutes-wrapper {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-top: 20px;
}

.featured-image {
  height: 80px;
  margin: 30px;
}
.counsellor-desc-wrapper > ul {
  padding: 10px;
}

@media only screen and (max-width: 1024px) {
  :root {
    --meet-counsellor-wrapper-paddng: 20px;
    --image-desc-width: 90vw;
    --image-size: 500px;
    --shadow-gap: 10px;
    --qualifications-image: 100px;
  }
  .meet-counsellor-wrapper {
    margin-bottom: 30px;
  }
  .meet-counsellor-header {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .counsellor-qualifications-image {
    margin: 20px;
  }
  .counsellor-desc-wrapper {
    height: auto;
    max-width: 100%;
    border-radius: 15px;
    background-color: white;
    margin-top: calc(var(--shadow-gap) * 2 + 5px);
    padding: 10px;
    padding-left: 10px;
    padding-left: 20px;
  }
  .counsellor-desc-wrapper > ul {
    padding: 10px;
  }
  .counsellor-image-desc-wrapper {
    margin-right: 0;
    flex-direction: column;
  }
  .meet-counsellor-policies-wrapper {
    width: 100vw;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    flex-direction: column-reverse;
    padding-bottom: 20px;
  }

  .meet-counsellor-policies-pointers {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    margin-bottom: 40px;
  }

  .pointer-tick {
    font-size: 28px;
  }
  .counsellor-qualifications-pointer {
    margin-right: 30px;
  }
  .featured-wrapper {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    --image-size: 300px;
    --qualifications-image: 80px;
  }
  .featured-image {
    height: 60px;
    margin: 20px;
  }
}

@media only screen and (max-width: 400px) {
  :root {
    --image-size: 220px;
    --qualifications-image: 70px;
  }
  .featured-image {
    height: 40px;
    margin: 15px;
  }
}
@media only screen and (max-width: 300px) {
  :root {
    --image-size: 100px;
    --qualifications-image: 60px;
  }
}

.wrapper {
  width: 100%;
  min-height: 30vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 2vw;
  padding-right: 2vw;
  background: var(--light-yellow);
}
.header-wrapper {
  width: 100%;
  margin-bottom: 30px;
}
.waitlist-input-wrapper {
  width: 100%;
  margin-bottom: 30px;
}
.socials-wrapper {
  width: 10%;
  margin-top: 2vh;
}
.social {
  height: 100%;
  width: 10%;
  font-size: 30px;
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    background: var(--light-yellow);
  }
  .header-wrapper {
    margin-bottom: 10px;
    text-align: center;
  }
  .socials-wrapper {
    width: 60%;
  }
}

.logo-row-container{
    margin: 10px;
    width: 100%;
}

.logo-container-one{
    width: 60%;
}

.logo-container-two{
    width: 40%;
}

.publicity-logo{
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 40%;
    max-height: 80px;
    /* height: 100%; */
    margin: 10px;
    margin-right: 50px;
    margin-left: 50px;
}

.st-logo{
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 40%;
    max-height: 30px;
    /* height: 100%; */
    margin: 10px;
    margin-right: 50px;
    margin-left: 50px;
}

.grid-row{
    display: block;
}


@media only screen and (max-width: 1024px) {
    .logo-row-container{
        /* flex-direction: column; */
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 30px;
    }

    .publicity-logo{
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 33%;
        max-height: 45px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .st-logo{
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 80%;
        max-height: 18px;
        /* height: 100%; */
        margin: 30px;
    }
}
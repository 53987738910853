.full-vw {
  width: 100vw;
}

.full-vh {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-max-width {
  max-width: 100%;
}

.full-max-height {
  max-height: 100%;
}

.flex-row-space-evenly-center {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.flex-row-space-around-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.flex-row-space-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-row-space-between-start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
}
.flex-row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-space-around-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-column-center-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-column-space-around-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-column-start-end {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.button-header{
    width: 100%;
    text-align: center;
  }
  .store-buttons {
    width: 100%;
    flex-wrap: wrap;
  }
  .store-image-android {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 60px;
    width: auto;
  }
  .store-image-ios {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 62.5px;
    width: auto;
  }
  @media only screen and (max-width: 1024px) {
    .store-image-android {
      height: 40px;
      width: auto;
    }
    .store-image-ios {
      height: 41.5px;
      width: auto;
    }
  }
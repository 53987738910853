.features-wrapper{
    width: 100%;
}
.feature-section-wrapper {
    width: 30%;
    min-height: 40vh;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 80px;
}
.section-text{
 width: 90%;
 text-align: center;
 min-height: 80px;
}
.section-image{
    margin-top: 20px;
    width: 80%;
}


@media only screen and (max-width: 1020px) {
    .feature-section-wrapper {
        margin-top: 30px;
    }
    .section-text{
        min-height: 0;
    }
}

@media only screen and (max-width: 700px) {
    .feature-section-wrapper {
        width: 100%;
        margin-top: 30px;
    }
    .features-wrapper{
        flex-direction: column;
    }
    .section-text{
        min-height: 0;
        margin-bottom: 20px;
        max-width: 360px;
    }
    .section-image{
        margin-top: 10px;
        width: 300px;
    }
}
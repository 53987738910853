:root {
  --yellow: #ffc633;
  --blue: #0997ff;
  --light-blue: #e4fffc;
  --aquamarine: #6fffb1;
  --grey: #fafafa;
  --light-yellow: #fff4d6;
  --green: #34ee89;
  --black: #2c3e50;
  --light-purple: #e1e5f0;
  --light-green: #e1f0ee;
}

mark {
  background-color: #6fffb1;
}

.font-48-800 {
  font-family: Avenir-black;
  font-size: 48px;
}

.font-46-700 {
  font-family: Avenir-black;
  font-size: 46px;
}

.font-24-900 {
  font-size: 24px;
  font-family: Avenir-black;
}

.font-58-900 {
  font-family: Avenir-black;
  font-size: 58px;
}

.font-36-800 {
  font-family: Avenir-black;
  font-size: 36px;
}

.font-24-500 {
  font-size: 24px;
  font-weight: 500;
}

.font-20-500 {
  font-size: 20px;
  font-family: Avenir-black;
}

.font-70-800 {
  font-size: 70px;
  font-weight: 800;
}

.font-72-900 {
  font-family: Avenir-black;
  font-size: 72px;
}

.bullet-point {
  font-family: Avenir;
  font-weight: medium;
  font-size: 18px;
}

.font-22-900 {
  font-family: Avenir-black;
  font-size: 22px;
}

.font-22-600 {
  font-size: 22px;
  font-weight: 600;
}

.font-22-400 {
  font-size: 22px;
  font-weight: 400;
}

.font-28-400 {
  font-size: 28px;
  font-weight: 400;
}

.font-32-500 {
  font-size: 32px;
  font-weight: 500;
}

.font-34-500 {
  font-size: 34px;
  font-weight: 500;
}

.font-34-700 {
  font-size: 34px;
  font-weight: 700;
}
.font-18-500 {
  font-size: 18px;
  font-weight: 500;
}
.font-14-500 {
  font-size: 14px;
}
.font-14-900 {
  font-size: 14px;
  font-family: Avenir-black;
}
.font-13-800 {
  font-size: 13px;
  font-family: Avenir-black;
}

.font-18-900 {
  font-size: 18px;
  font-family: Avenir-black;
}

.font-22-900 {
  font-size: 22px;
  font-family: Avenir-black;
}

.font-18-800 {
  font-size: 18px;
  font-family: Avenir-black;
}
.font-25-800 {
  font-size: 25px;
  font-family: Avenir-black;
}
.font-24-800 {
  font-size: 24px;
  font-family: Avenir-black;
}
.font-50-700 {
  font-family: Avenir-black;
  font-size: 50px;
}

/* button text */
.ant-btn .ant-input {
  font-size: 12px;
}

.ant-btn {
  border-color: transparent !important;
}

.logo-image {
  max-width: 25%;
  height: auto;
  margin: 10vh 5vw 10vh 5vw;
  z-index: 1;
}
.wave-image {
  max-width: 100%;
  height: auto;
  position: fixed;
  bottom: 0px;
  opacity: 0.7;
  z-index: 0;
}
@media only screen and (max-width: 1024px) {
  .font-48-800 {
    font-size: 35px;
    font-weight: 800;
  }
}
@media only screen and (max-device-width: 500px) {
  .logo-image {
    max-width: 50%;
  }
  .wave-image {
    height: 50%;
  }
  .font-48-800 {
    font-size: 25px;
    font-weight: 800;
  }
  

  .font-58-900 {
    font-size: 36px;
    font-weight: 900;
  }

  .font-36-800 {
    font-size: 23px;
    font-weight: 800;
  }

  .font-24-500 {
    font-size: 16px;
    font-weight: 500;
  }

  .font-24-900 {
    font-size: 20px;
    font-family: Avenir-black;
  }

  .font-28-400 {
    font-size: 18px;
    font-weight: 400;
  }

  .font-70-800 {
    font-size: 45px;
    font-weight: 800;
  }

  .font-72-900 {
    font-family: Avenir-black;
    font-size: 55px;
  }

  .font-22-600 {
    font-size: 15px;
    font-weight: 600;
  }

  .font-22-400 {
    font-size: 15px;
    font-weight: 400;
  }

  .font-22-900 {
    font-size: 18px;
    font-family: Avenir-black;
  }

  .font-16-900 {
    font-size: 16px;
    font-weight: 900;
  }

  .font-32-500 {
    font-size: 22px;
    font-weight: 500;
  }

  .font-34-500 {
    font-size: 24px;
    font-weight: 500;
  }

  .font-34-700 {
    font-size: 24px;
    font-weight: 700;
  }

  .font-50-700 {
    font-size: 30px;
    font-weight: 700;
  }

  .bullet-point {
    font-family: Avenir;
    font-weight: medium;
    font-size: 15px;
  }
}

.pricing-wrapper {
  width: 100%;
  min-height: 40vh;
  padding: 20px;
  margin-top: 20px;
}

.pricing-wrapper > text {
  margin-top: 10px;
}

.pricing-tile-row-wrapper {
  width: 100%;
}

.pricing-tile-wrapper-small {
  margin: 20px;
  height: 290px;
  width: 270px;
  background-color: var(--light-purple);
  border-radius: 10px;
  padding: 25px;
}

.pricing-tile-wrapper-long {
  margin: 20px;
  height: 158px;
  width: 858px;
  background-color: var(--light-yellow);
  border-radius: 10px;
  padding: 25px;
}

.pricing-tile-header {
  text-align: center;
  margin-top: 10px;
}

.pricing-tile-header-big {
  text-align: center;
  margin-top: 30px;
}

.pricing-tile-desc > div {
  text-align: center;
  margin-top: 10px;
}
.pricing-tile-desc {
  min-height: 65%;
  padding: 5px;
}

.pricing-tile-desc-long {
  min-height: 30%;
  padding: 5px;
}

.pricing-tile-desc-big > div {
  text-align: center;
  margin-top: 10px;
}
.pricing-tile-desc-big {
  text-align: center;
  padding: 5px;
  min-height: 60%;
}
.pricing-tile-price {
  min-height: 50px;
}

.pricing-wrapper-button {
  height: 40px;
  background-color: var(--aquamarine);
  color: var(--black);
}

.pricing-tile-wrapper-big {
  margin: 20px;
  height: 320px;
  width: 300px;
  background-color: var(--light-green);
  border-radius: 10px;
  position: relative;
}

.pricing-tile {
  height: 100%;
  width: 100%;
}

.tick-mark {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 35px;
}

@media only screen and (max-width: 1300px) {
  .pricing-tile-wrapper-big {
    height: 310px;
    width: 300px;
  }
  .pricing-tile-wrapper-small {
    height: 290px;
    width: 270px;
  }

  .pricing-tile-wrapper-long {
    height: 290px;
    width: 500px;
  }
}

@media only screen and (max-width: 1024px) {
  .pricing-wrapper {
    width: 100%;
    min-height: 40vh;
    padding: 20px;
    margin-top: 0px;
  }
  .pricing-tile-row-wrapper {
    flex-direction: row;
    align-items: center;
  }
  .pricing-tile-wrapper-small {
    margin: 5px;
  }
  .pricing-tile-wrapper-big {
    margin: 5px;
  }

  .pricing-tile-wrapper-long {
    height: 290px;
    width: 400px;
  }
}

@media only screen and (max-width: 670px) {
  .pricing-tile-wrapper-big {
    height: 340px;
    width: 310px;
  }
  .pricing-tile-wrapper-small {
    height: 310px;
    width: 290px;
  }
  .pricing-wrapper {
    margin-top: 0px;
  }
  .pricing-tile-row-wrapper {
    flex-direction: column;
  }
  .pricing-tile-wrapper-small {
    margin: 10px;
  }
  .pricing-tile-wrapper-big {
    margin: 10px;
  }

  .pricing-tile-wrapper-long {
    height: 290px;
    width: 350px;
  }
}
